const Colors = [
    "#bfc279",
    "#eeba46",
    "#2922e6",
    "#e38a6d",
    "#da0ac9",
    "#d8ab4c",
    "#776b1c",
    "#11d93f",
    "#4c1326",
    "#a117ab",
    "#557fe5",
    "#de079f",
    "#698495",
    "#5be7af",
    "#65ec0c",
    "#aca1c8",
    "#433902",
    "#235d31",
    "#e75166",
    "#03badb",
    "#e88f21",
    "#dce1e5",
    "#d5d1b8",
    "#d26e20",
    "#80ac24",
    "#d9fcd2",
    "#ddb9e0",
    "#eaea8a",
    "#f4ef30",
    "#87b8f0",
    "#7658ab",
    "#f674d3",
    "#76ab4d",
    "#96250f",
    "#40e89a",
    "#befc4a",
    "#b5e395",
    "#475700",
    "#6d777e",
    "#834d66",
    "#e68d09",
    "#1178f0",
    "#b34853",
    "#aae6cf",
    "#ea17fa",
    "#dc930e",
    "#cbe453",
    "#8997b6",
    "#5c4217",
    "#e90283",
    "#527efd",
    "#88eac5",
    "#41bae6",
    "#af8170",
    "#5031f9",
    "#a400fa",
    "#46930d",
    "#131e3d",
    "#b55749",
    "#bd0756",
    "#e35ae4",
    "#451351",
    "#dde9c8",
    "#9b3b6d",
    "#c7f012",
    "#eefa2a",
    "#a69170",
    "#7aff6b",
    "#220245",
    "#581f91",
    "#35b700",
    "#782fe7",
    "#31ce8c",
    "#06fb14",
    "#2b2888",
    "#96ab14",
    "#14bd1f",
    "#573ea1",
    "#76aff5",
    "#8ecbfe",
    "#b56451",
    "#31fbe9",
    "#2cd089",
    "#e87fe3",
    "#b28abc",
    "#a68e0b",
    "#15cf7d",
    "#e6c6ad",
    "#c85db7",
    "#f595cc",
    "#ed4fc8",
    "#dcaa0e",
    "#828ec0",
    "#1b3a16",
    "#0a28bd",
    "#2b09ec",
    "#8d81a6",
    "#5410d7",
    "#37f677",
    "#1dc68a"
];

export default Colors
