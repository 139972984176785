import React from 'react';
import { Route } from 'react-router-dom';

function PublicRoute({ children,  ...props }) {
    // debugger;
    return (
        <>
          
            <Route {...props}
                render={
                    ({ location }) => (
                        (children)
                    )
                }
            /></>
    );
}

export default PublicRoute;