import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
//import PieChart, { Series, Label, Connector, Export, Tooltip, Size, Legend } from 'devextreme-react/pie-chart';
import { DataGrid, Column, HeaderFilter, Selection, Export } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/exporter/exceljs/excelExporter';


import './OnHoldInvoices.scss';
import Colors from './Colors.js';

const url = process.env.REACT_APP_API_URL + "/api";
const disbursements_site_id = "f1a33e6c-ea3b-4db4-8a5b-635fdac7f5fb";
const accountspayable_site_id = "04f26e27-779e-41e3-9bc7-4280449aaeba";
const imaging_site_id = "16639b84-0105-4e9b-b818-db25010c14c4";
//const onhold_list_id = "3d36af1e-a785-480e-8312-1cbf0280a750";//AP_Report_OnHold
const dept_list_id = "4bfeb8d0-ef2f-4352-b522-11df7f93abee";//AP_Dept_Crosswalk


function OnHoldInvoices(props) {
    const history = useHistory();
    //const [token, setToken] = useState("");
    const [depts, setDepts] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [selectedDepts, setSelectedDepts] = useState([]);

    /*const [dateRefresh, setDateRefresh] = useState("");*/
    const [showLoading, setShowLoading] = useState(true);


    useEffect(() => {
        getToken().then((token) => {
            getDepts(token).then((deptArray) => {
                getTokenRestAPI().then((tokenRestAPI) => {
                    getOnholdInvoices(tokenRestAPI, deptArray).then((invoiceArray) => {
                    });
                });
            });
        });
    }, []);


    async function getToken() {
        let response = await fetch(url + "/Sharepoint/GetToken", {
            method: "GET"
        });

        let token = await response.text()
            .then(t => {
                if (t === "") {
                    //setErrorMsg("Token Unauthorized.");
                } else {
                    //setToken(t);
                    return t;
                }
            });
        return token;
    }
    async function getTokenRestAPI() {
        let response = await fetch(url + "/Sharepoint/GetTokenRestAPI", {
            method: "GET"
        });

        let tokenRestAPI = await response.text()
            .then(t => {

                if (t === "") {
                    setErrorMsg("Token Unauthorized.");
                }
                else {
                    return t;
                }
            });

        return tokenRestAPI;
    }


    const getDepts = async (token) => {

        setDepts([]);
        //setErrorMsg("");

        let sharepointUrl = "https://graph.microsoft.com/v1.0/sites/" + disbursements_site_id
            + "/sites/" + accountspayable_site_id
            + "/sites/" + imaging_site_id
            + "/lists/" + dept_list_id
            + "/items?expand=fields&$top=1000";

        let tempArray = await fetch(sharepointUrl, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Prefer': 'allowthrottleablequeries'
            }
        }).then(result => result.json()

        ).then(myJson => {

            if (myJson.error) {
                setErrorMsg(myJson.error.message);

            }
            else {
                let responseArray = myJson.value;
                let deptArray = [];
                for (let responseObj of responseArray) {

                    let deptObj = {
                        "Id": responseObj.id,
                        "DeptCode": responseObj.fields.DeptCode,
                        "Title": responseObj.fields.Title
                    };
                    deptArray.push(deptObj);
                }
                //setDepts(deptArray);
                return deptArray;
            }
        }).catch((error) => {
            setErrorMsg(error);

        });
        return tempArray;
    }

    const getOnholdInvoices = async (tokenRestAPI, deptArray) => {

        setInvoices([]);
        setErrorMsg("");

        let sharepointUrl = "https://atcgov.sharepoint.com/sites/Disbursements/AccountsPayable/Imaging/_api/web/GetFolderByServerRelativeUrl('Invoices/Processing')"
            + "/files?$expand=ListItemAllFields"
            + "&$filter=ListItemAllFields/Status eq 'On Hold'"

        fetch(sharepointUrl, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + tokenRestAPI,
                'Prefer': 'allowthrottleablequeries',
                'Accept': 'application/json; odata=verbose',
            }
        }).then(result => result.json()
        ).then(myJson => {
            if (myJson.error) {
                setErrorMsg(myJson.error.message);
            }
            else {
                let responseArray = myJson.d.results;
                let invoiceArray = [];

                for (let responseObj of responseArray) {

                    let invoiceObj = {
                        "Id": responseObj.ListItemAllFields.Id,
                        "POnumber": responseObj.ListItemAllFields.POnumber,
                        "InvoiceNo": responseObj.ListItemAllFields.InvoiceNo,
                        "Assigned": responseObj.ListItemAllFields.Assigned,
                        "eComment": responseObj.ListItemAllFields.eComments,
                        "Sender": responseObj.ListItemAllFields.Sender,
                        "OnHoldReason": responseObj.ListItemAllFields.OnHoldReason,
                        "DeptCode": responseObj.ListItemAllFields.Dept_x0020_Code,
                        "ReceivedDate": responseObj.ListItemAllFields.ReceivedDate,
                        "Modified": responseObj.ListItemAllFields.Modified
                    };
                    invoiceArray.push(invoiceObj);
                }
                return buildInvoiceList(invoiceArray, deptArray);
            }
        }).catch((error) => {
            setErrorMsg(error);

        });
    }
    const buildInvoiceList = async (invoiceArray, deptArray) => {
        //debugger;
        deptArray.unshift({ "Id": "0", "DeptCode": null, "Title": "(Blank)" });

        //fill DeptName in invoice
        for (let invoice of invoiceArray) {
            invoice.DeptName = null;

            if (invoice.DeptCode !== null) {
                let dept = deptArray.filter(x => x.DeptCode === invoice.DeptCode);
                if (dept.length > 0) {
                    invoice.DeptName = dept[0].Title;
                }
            }
          
        }

        //Get Array with distinct Dept Title
        let DeptArrayWOCode = [...new Map(deptArray.map(item => [item['Title'], item])).values()];
       

        //count invoice by depts name
        for (let dept of DeptArrayWOCode) {
            dept.Count = 0;

            if (dept.Title === "(Blank)") {

                dept.Count = invoiceArray.filter(x => x.DeptCode === null).length;
            }
            else {
                for (let invoice of invoiceArray) {
                    if (invoice.DeptName === dept.Title) {
                        dept.Count = dept.Count + 1;
                    }
                }
            }

            dept.TitleWithCount = dept.Title + " (" + dept.Count + ")";
        }

       
        //filter and sort depts
        let newDeptArray = DeptArrayWOCode
            .filter((x) => x.Count !== 0)
            .sort((a, b) => {
                const nameA = a.Title.toUpperCase();
                const nameB = b.Title.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            }
            );

        setDepts(newDeptArray);


        setInvoices(invoiceArray);
        setSelectedInvoices(invoiceArray);
        setShowLoading(false);
    }


    //Use this utility function to gererate random color
    //const generateRandomColor = () => {
    //    let aa = [];
    //    for (let i = 0; i < 100; i++) {
    //        let randomHex = Math.floor(Math.random() * 0xffffff).toString(16);
    //        randomHex = "#" + randomHex.padStart(6, "0");
    //        aa.push(randomHex);
    //    }
    //    console.log(aa);
    //}

    const onClickSelectDept = (e, dept) => {

        let tempInvoiceArray = [];
        let tempSelectedDeptsArray = [...selectedDepts];
        if (tempSelectedDeptsArray.filter(x => x === dept.Title).length > 0) {
            tempSelectedDeptsArray = tempSelectedDeptsArray.filter(x => x !== dept.Title);
        }
        else {
            tempSelectedDeptsArray.push(dept.Title);
        }
        if (tempSelectedDeptsArray.length > 0) {
            tempInvoiceArray = invoices.filter(x => tempSelectedDeptsArray.includes(x.DeptName??"(Blank)"));
        }
        else {
            tempInvoiceArray = invoices;
        }

        setSelectedDepts(tempSelectedDeptsArray);
        setSelectedInvoices(tempInvoiceArray);

    }

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
        });
        e.cancel = true;
    }


    const onClickBack = (e) => {
        history.push("/");
        e.preventDefault();


    }

    const maskedSenderDisplayValue = (data) => {
        if (data.Sender && data.Sender.length > 0) {
            let domain = data.Sender.substring(data.Sender.indexOf("@"), data.Sender.length);
            let name = data.Sender.substring(0, data.Sender.indexOf("@"));
         
            name=  name.slice(0, 1) + "***" + name.slice(-1);
            let maskedSender = name + domain;
            return maskedSender;
        }
        else {
            return data.Sender;
        }
    }

    return (
        <div>
            <div className="pageHeader">
                <a className="linkBack" href="#" onClick={onClickBack}> Back </a>
                On Hold Invoices
            </div>

            <div className="card" >
                <div className="divTitle">Click on the Department and scroll down to view detail</div>
                <div className="divSubTitle">(Select all that apply)</div>


                {depts.length > 0 && <Row>
                    {depts.map((dept, i) =>

                        <Col lg={3} md={4} sm={4} xs={6} key={i}>
                            <div
                                className={selectedDepts.filter(x => x === dept.Title).length > 0 ? "divDept divDeptSelected" : "divDept"}
                                onClick={(e) => onClickSelectDept(e, dept)}
                            >
                                <div className="divMarker" style={{ backgroundColor: Colors[i] }}></div>
                                <div className="divLabel">{dept.TitleWithCount}</div>
                            </div>
                        </Col>
                    )}


                </Row>}

                <div className="totalOnHoldInvoiceCount">Total On Hold Invoices: {invoices.length}</div>
            </div>
            <div className="card">
                <DataGrid
                    id="dgInvoice"
                    dataSource={selectedInvoices}
                    keyExpr="Id"
                    rowAlternationEnabled={true}
                    hoverStateEnabled={true}
                    showBorders={true}
                    columnAutoWidth={true}
                    onExporting={onExporting}
                >
                    <Selection mode="multiple" />
                    <HeaderFilter visible={false} />
                    <Column dataField="ReceivedDate" dataType="date" defaultSortOrder="asc" sortIndex={1} />
                    <Column dataField="DeptCode" caption="Dept Code"  />
                    <Column dataField="DeptName" caption="Dept Name" defaultSortOrder="asc" sortIndex={0} />
                    <Column dataField="InvoiceNo" caption="Invoice Number" />
                    <Column dataField="POnumber" caption="PO Number" />
                    <Column dataField="OnHoldReason" caption="On-Hold Reason" />
                    <Column dataField="eComment" caption="Comment" />

                    <Export enabled={true} allowExportSelectedData={true} />
                </DataGrid>

            </div>

            {showLoading && <div className="divLoadingPanel">
                <div id="divLoading">
                    <div id="ball-1" className="circle"></div>
                    <div id="ball-2" className="circle"></div>
                    <div id="ball-3" className="circle"></div>
                    <div className="fetching">
                        Fetching live data ...
                    </div>
                </div>
            </div>}

        </div>

    );

}

export default OnHoldInvoices;