import React from 'react';

function FAQ(props) {
    return (
        <div>
            <p className="faq-question">
                How do I get a Purchase Order Number?
            </p>
            <p>
                The SAP Center of Excellence has created work instructions that will assist you. <a href="https://atccl.sbcounty.gov/sap/lessons/create-supplier-relationship-management-documents-contracts-shopping-carts-portal/" target="_blank" rel="noopener noreferrer">Click here</a> to view.
            </p>
            <br/>
            <p className="faq-question">
                Why is my Purchase Order incomplete?
            </p>
            <p>
                The Invoice could be in Saved or Awaiting Approval status; please contact your Purchasing buyer for further action.
            </p>
            <br />
            <p className="faq-question">
                Why is my invoice in an  ‘On Hold’ status?
            </p>
            <p>
                Your invoice could be in an ‘On Hold’ status due to: a <a href="https://atccl.sbcounty.gov/sap/knowledge-base/warning-message-price-too-high-tolerance-limit-of-250-00-exceeded/" target="_blank" rel="noopener noreferrer">price mismatch</a>, amount exceeds PO <a href="https://atccl.sbcounty.gov/sap/knowledge-base/what-are-the-system-tolerances/" target="_blank" rel="noopener noreferrer">tolerance</a>, a vendor mismatch (PO was created for incorrect vendor), a <a href="https://atccl.sbcounty.gov/sap/knowledge-base/warning-message-quantity-invoiced-greater-than-goods-receipt-quantity/" target="_blank" rel="noopener noreferrer">quantity mismatch</a> against PO, or <a href="https://atccl.sbcounty.gov/sap/knowledge-base/what-is-a-tax-code/" target="_blank" rel="noopener noreferrer">tax code</a> is incorrect. – If a change is needed on your Purchase Order, please contact your Purchasing buyer for further assistance. If the vendor invoice is incorrect, please contact your vendor to request a revised invoice be sent to ATC-AP for processing.
            </p>
            <br />
            <p className="faq-question">
                What is the Final Invoice Indicator?
            </p>
            <p>
                The SAP Center of Excellence website has the answer <a href="https://atccl.sbcounty.gov/sap/knowledge-base/error-message-final-invoice-indicator-set-for-the-purchase-order-item/" target="_blank" rel="noopener noreferrer">here</a>.
            </p>
            <br />
            <p className="faq-question">
                I searched for my invoice but no results were returned.
            </p>
            <p>
                If there are no results for your search, it could mean that the invoice has either not been received by ATC-AP or has not yet been indexed. Invoices received by ATC-AP are indexed to allow users to search by PO number and invoice number. Indexing is typically completed within 2-3 days of receipt. If an invoice was just recently submitted to ATC-AP for processing, users should wait 2-3 days to check status of invoices.
            </p>
            <br />
            <p className="faq-question">
                What are the Description of the various Statuses?
            </p>
            
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Status</th>
                            <th scope="col">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Received</td>
                            <td>In processing queue; Original Submission auto-indexed due to recommended naming convention</td>
                        </tr>
                        <tr>
                            <td>To Be Processed</td>
                            <td>In processing queue; E-mail/Mail submission manually indexed</td>
                        </tr>
                        <tr>
                            <td>Processing</td>
                            <td>Assigned to creator for entry into SAP</td>
                        </tr>
                        <tr>
                            <td>On Hold</td>
                            <td>Unable to process due to indicated reason(s); Email AP Inquiries when issue is resolved</td>
                        </tr>
                        <tr>
                            <td>Keyed in SAP</td>
                            <td>Use SAP document number to follow workflow in SAP</td>
                        </tr>
                    </tbody>
                </table>
            
        </div>
     )
}
export default FAQ;