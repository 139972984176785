import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, { Component } from 'react';
import './App.scss';
import './dx-styles.scss';
import "./utils/bootstrap/bootstrap.min.css";
import { Container } from 'react-bootstrap';
//import sbclogo from "./img/sb_cologo-full_color.png";
import InvoiceStatus from './components/invoice/InvoiceStatus';
import OnHoldInvoices from './components/onhold/OnHoldInvoices';
import { HashRouter as Router, Switch } from 'react-router-dom';
import PublicRoute from './_routes/PublicRoute';

class App extends Component {
    constructor(props) {
        super(props);
    }

    //componentDidMount() {

    //}


    render() {

        return (
            <div className="wrapper">
                <div className="header">
                    {/* <img className="logo" src={sbclogo} alt="logo" />*/}
                    <div className="logo"></div>
                    <div className={'title'}>
                        Invoice Status
                    </div>
                </div>
                <div className="mainContent">
                    <Container >

                        <Router>
                            <Switch>
                                <PublicRoute exact path="/">
                                    <InvoiceStatus />
                                </PublicRoute>
                                <PublicRoute exact path="/OnHoldInvoices">
                                    <OnHoldInvoices />
                                </PublicRoute>
                            </Switch>
                        </Router>

                    </Container>
                </div>
                <div className="footer">
                    Copyright © {(new Date().getFullYear())} San Bernardino County Auditor-Controller/Treasurer/Tax Collector - All Rights Reserved
                </div>
            </div>
        );
    }




}

export default App;
