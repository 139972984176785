import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './InvoiceStatus.scss';
//import IPRanges from './IPRanges.js'
import FAQ from './FAQ.js';
import { Form, Col, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'devextreme-react/button';
import { DataGrid, Column, HeaderFilter } from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Tooltip } from 'devextreme-react/tooltip';
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import ReCAPTCHA from "react-google-recaptcha";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import ReactGA from "react-ga4";

const reCaptchaSiteKey = "6LeXX_EjAAAAAB0vAfA3Y2L_5iSGIdcmDc2F8SVy";
const url = process.env.REACT_APP_API_URL + "/api";

function InvoiceStatus(props) {
    const history = useHistory();
    const reCaptchaRef = useRef();//React.createRef();
    const [token, setToken] = useState("");
    const [POnumber, setPOnumber] = useState("");
    const [InvoiceNo, setInvoiceNo] = useState("");
    const [invoices, setInvoices] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [loadPanelVisible, setLoadPanelVisible] = useState(false);
    const [useReCaptcha, setUseRecaptcha] = useState(true);
    const [workingReceivedDate, setWorkingReceivedDate] = useState("Calculating...");
    const [toolTipVisible, setToolTipVisible] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [toolWSTipVisible, setWSToolTipVisible] = useState(false);
    const [sapMSGVisible, setSapMSGVisible] = useState(false);
    //const [indexDate, setIndexDate] = useState("Calculating...");
    const [showInvalidCharacterMSG, setShowInvalidCharacterMSG] = useState(false);
    
    useEffect(() => {
        getToken();
        getTokenRestAPI().then((tokenRestAPI) => { getReceivedDateRestAPI(tokenRestAPI); /*getIndexedDateRestAPI(tokenRestAPI)*/});
    }, []);

    //function getOffset(el) {
    //    debugger;
    //    if (el !== null)
    //    {
    //        const rect = el.getBoundingClientRect();
    //        return "'" + rect.left + "px'";
            
    //        //return {
    //        //    left: rect.left + window.scrollX,
    //        //    top: rect.top + window.scrollY
    //        //    };
    //    }
    //  }

    async function getToken() {
        let response = await fetch(url + "/Sharepoint/GetToken", {
            method: "GET"
        });

        let token = await response.text()
            .then(t => {
                if (t === "") {
                    setErrorMsg("Token Unauthorized.");
                } else {
                    setToken(t);
                    return t;
                }
            });
        return token;
    }

    async function getTokenRestAPI() {
        let response = await fetch(url + "/Sharepoint/GetTokenRestAPI", {
            method: "GET"
        });

        let tokenRestAPI = await response.text()
            .then(t => {
               
                if (t === "") {
                    setErrorMsg("Token Unauthorized.");
                }
                else {
                    return t;
                }
            });
        
        return tokenRestAPI;
    }

    const toggleShowInvalidCharacterMSG = () => {
        //debugger;
        setShowInvalidCharacterMSG(!showInvalidCharacterMSG);
    }

    const onChangePO = (event) => {
        setPOnumber(event.target.value);
    }

    const onKeyDownInvoiceNo = (event) => {
        const key = event.key;
        const specialKeys = ['@', '#', '%', '&', '*', ':', ';', "'", '"', '/', '?'];

        if (specialKeys.includes(key))
        {
            toggleShowInvalidCharacterMSG();
            //alert('A character was detected that is not allowed in the Invoice No. field.\nThe following special characters will automatically be omitted from the Invoice Number : @  #  %  &  *  :  ;  ’  ”  /  ?.');
        }
    }

    const onChangeInvoiceNo = (event) => {
        const filteredValue = event.target.value.replace(/[@#%&*:;'"/?]/g, "");
        setInvoiceNo(filteredValue);
    }

    /*
    const getIndexedDateRestAPI = (tokenRestAPI) => {
       
        if (tokenRestAPI === undefined) {
            return;
        }
        
        let sharepointUrl = "https://atcgov.sharepoint.com/sites/Disbursements/AccountsPayable/Imaging/_api/web/GetFolderByServerRelativeUrl('Invoices/Emailed%20Invoices')"
            + "/files?$expand=ListItemAllFields"
            + "&$orderby=ListItemAllFields/Emailed asc"
            + "&$top=1"
        
        fetch(sharepointUrl, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + tokenRestAPI,
                'Prefer': 'allowthrottleablequeries',
                'Accept': 'application/json; odata=verbose',
            }
        }).then(result => result.json()

        ).then(myJson => {
            if (myJson.error) {
                setErrorMsg(myJson.error.message);
            }
            else {
                let responseArray = myJson.d.results;
                for (let responseObj of responseArray) {
                    setIndexDate(formatDate(new Date(responseObj.ListItemAllFields.Emailed)));
                    break;
                }                
            }
        }).catch((error) => {
            setErrorMsg(error);
        });
    }
    */
    
    const getReceivedDateRestAPI = (tokenRestAPI) => {       
        if (tokenRestAPI === undefined) {
            return;
        }
        
        let sharepointUrl = "https://atcgov.sharepoint.com/sites/Disbursements/AccountsPayable/Imaging/_api/web/GetFolderByServerRelativeUrl('Invoices/Processing')"
            + "/files?$expand=ListItemAllFields"
            + "&$filter=ListItemAllFields/Status ne 'On Hold' and ListItemAllFields/Assigned ne null"
            + "&$orderby=ListItemAllFields/ReceivedDate asc"
            + "&$top=1"
        
        fetch(sharepointUrl, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + tokenRestAPI,
                'Prefer': 'allowthrottleablequeries',
                'Accept': 'application/json; odata=verbose',
            }
        }).then(result => result.json()

        ).then(myJson => {            
            if (myJson.error) {
                setErrorMsg(myJson.error.message);
            }
            else {
                let responseArray = myJson.d.results;
                for (let responseObj of responseArray) {
                  
                    setWorkingReceivedDate(formatDate(new Date(responseObj.ListItemAllFields.ReceivedDate)));
                    break;
                }
                
            }
        }).catch((error) => {
            setErrorMsg(error);
        });
    }

    const formatDate = (newDate) => {
        var dd = newDate.getDate();
        var mm = newDate.getMonth() + 1;

        var yyyy = newDate.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var today = mm + '/' + dd + '/' + yyyy;

        return today;
    }

    const getData = () => {
        setSapMSGVisible(false);
        setInvoices([]);
        setErrorMsg("");
        setLoadPanelVisible(true);

        if (POnumber !== "" || InvoiceNo !== "") {
            //Get site and subsite id
            //https://atcgov.sharepoint.com/sites/Disbursements/_api/site/id
            //https://atcgov.sharepoint.com/sites/Disbursements/AccountsPayable/_api/web/id
            //https://atcgov.sharepoint.com/sites/Disbursements/AccountsPayable/Imaging/_api/web/id
            //Get list id
            //Go to the setting of the document library, the url will show the list id between %7B and %7D

            let disbursements_site_id = "f1a33e6c-ea3b-4db4-8a5b-635fdac7f5fb";
            let accountspayable_site_id = "04f26e27-779e-41e3-9bc7-4280449aaeba";
            let imaging_site_id = "16639b84-0105-4e9b-b818-db25010c14c4";
            let list_id = //"ff0b1572-5d8f-45e3-9571-8ca519c0c73e";//Test Library
                "beac1a99-68b9-4611-b35a-d869113509fc"; //Invoice

            let sharepointUrl = "https://graph.microsoft.com/v1.0/sites/" + disbursements_site_id
                + "/sites/" + accountspayable_site_id
                + "/sites/" + imaging_site_id
                + "/lists/" + list_id
                + "/items?expand=fields,driveItem";

            if (POnumber !== "" && InvoiceNo !== "") {
                sharepointUrl += "&filter=fields/POnumber eq '" + POnumber + "'and fields/InvoiceNo eq '" + InvoiceNo + "'";
            }
            else if (POnumber !== "") {
                sharepointUrl += "&filter=fields/POnumber eq '" + POnumber + "'";
            }
            else if (InvoiceNo !== "") {
                sharepointUrl += "&filter=fields/InvoiceNo eq '" + InvoiceNo + "'";
            }

            fetch(sharepointUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Prefer': 'allowthrottleablequeries'
                }
            }).then(result => result.json()

            ).then(myJson => {
                if (myJson.error) {
                    setErrorMsg(myJson.error.message);
                    setLoadPanelVisible(false);
                }
                else {
                    let responseArray = myJson.value;
                    let invoiceArray = [];
                    for (let responseObj of responseArray) {
                        if (responseObj.webUrl.indexOf("Do%20Not%20Process") === -1 && responseObj.webUrl.indexOf("Verified%20Duplicates%20and%20Deletes") === -1)
                        {                            
                            let invoiceObj = {
                                "Id": responseObj.id,
                                "POnumber": responseObj.fields.POnumber,
                                "InvoiceNo": responseObj.fields.InvoiceNo,
                                "SAPDocNo": responseObj.fields.SAP_x0020_Doc_x0020__x0023_,
                                "Status": responseObj.fields.Status,
                                "OnHoldReason": responseObj.fields.OnHoldReason,
                                "Modified": responseObj.fields.Modified,
                                "Comment": responseObj.fields.eComments
                            };
                            
                            if(invoiceObj.SAPDocNo !== undefined)
                            {
                                setSapMSGVisible(true);
                            }
                            invoiceArray.push(invoiceObj);
                        }
                    }
                    setInvoices(invoiceArray);
                    setLoadPanelVisible(false);
                }
            }).catch((error) => {
                setErrorMsg(error);
                setLoadPanelVisible(false);
            });
        }
        else {
            setErrorMsg("Please entry PO number or Invoice Number to search.");
            setLoadPanelVisible(false);
        }
    }

    const onSubmitWithReCAPTCHA = async () => {
        ReactGA.event({ category: "InvoiceStatus", action: "Search button clicked" });

        setErrorMsg("");
        const tokenReCaptcha = await reCaptchaRef.current.executeAsync();
        if (tokenReCaptcha) {
            reCaptchaRef.current.reset();
            return fetch(url + "/ReCaptcha/VerifyReCaptcha?tokenReCaptcha=" + tokenReCaptcha, {
                method: "GET"
            }).then(response => response.json())
                .then(result => {
                    if (result.success) {
                        getData();
                    } else {
                        setErrorMsg(result.error);
                    }
                });
        }
        else {
            setErrorMsg("Google ReCaptcha: tokenReCaptcha empty.");
        }
    }

    const reset = () => {
        ReactGA.event({ category: "InvoiceStatus", action: "Reset button clicked" });

        setPOnumber("");
        setInvoiceNo("");
        setInvoices([]);
        setErrorMsg("");
        setSapMSGVisible(false);
    }

    const onChangeReCaptcha = (value) => {
        //console.log("Captcha value:", value);
    }

    const calculateStatusDisplayValue = (data) => {
        if (data.Status === "Completed") {
            return "Keyed in SAP";
        }
        else {
            return data.Status;
        }
    }

    const calculateOnHoldReasonDisplayValue = (data) => {
        if (data.Status === "On Hold") {
            return data.OnHoldReason + ": " + formatComments(data.Comment); //.replace("\d{1,2}\/\d{1,2}\/\d{4}\s(\d{1,2}\:\d{1,2}\s[A|P]M)");
        }
        else {
            return "";
        }
    }

    const formatComments = comment => {
        var returnValue = "";
        // Only process "comment" if it contains a value
        if (comment) {
            const dates = comment.match(/\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2} [AP]M/g);
            const dateBlocks = [];
            let currentBlock = "";
            let currentDate = "";

            // if "dates" is not null, process, otherwise skip
            if (dates) {
                dates.forEach((date) => {
                    const startIndex = comment.indexOf(date);
                    if (currentDate) {
                        currentBlock = comment.substring(comment.indexOf(currentDate), startIndex);
                        dateBlocks.push(currentBlock);
                    }
                    currentDate = date;
                });

                dateBlocks.push(comment.substring(comment.indexOf(currentDate)));

                dateBlocks.forEach((comment) => {
                    returnValue += "\n" + comment;
                });
            }
            else {
                returnValue = "\n" + comment;
            }
        }
        return returnValue;
    }

    const toggleDefault = () => {
        setToolTipVisible(!toolTipVisible);
    }

    const toggleWSDefault = () => {
        setWSToolTipVisible(!toolWSTipVisible);
    }

    const renderWSContent = () => {
        return (
            "This date is live. As invoices are keyed and statuses change, this date may change. Refresh as needed."
        )
    }

    const renderContent = () => {
        return (
            "The following special characters should be omitting from the Invoice Number : @  #  %  &  *  :  ;  ’  ”  /  ?"
        )
    }

    const hidePopup = () => {
        setPopupVisible(false);
    }
    const showPopup = () => {
        ReactGA.event({ category: "InvoiceStatus", action: "FAQ button clicked" });
        setPopupVisible(true);
    }

    const viewOnHold=(e) => {
        history.push("/OnHoldInvoices");
        e.preventDefault();
    }

    return (
        <div>
           
            <div className="pageHeader">PO-Invoice Status</div>
            
            <div className="linkOnHold">
                <Alert key={'warning'} variant={'warning'}>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: "#e7c30d", }} />&nbsp;&nbsp;
                    <Alert.Link href="#" onClick={viewOnHold}>View All On Hold Invoices By Department</Alert.Link>
                    &nbsp;&nbsp;<FontAwesomeIcon icon={faTriangleExclamation} style={{ color: "#e7c30d", }} />
                </Alert>
             </div>
            <Form.Group as={Row} className="mb-3" >
                <Form.Label column xs={12} md={"auto"} >
                    <b>PO Number:</b>
                </Form.Label>
                <Col xs={12} md={2} >
                    <Form.Control type="text"
                        name="POnumber"
                        value={POnumber}
                        onChange={onChangePO}
                        maxLength="255"
                    />
                </Col>
                <Form.Label column xs={12} md={"auto"} >
                    <b>
                        Invoice Number&nbsp;
                        <i className="dx-icon-help dx-icon-custom-style"
                            id="spCharacters"
                            onMouseEnter={toggleDefault}
                            onMouseLeave={toggleDefault}
                        ></i>:
                        <Tooltip
                            target="#spCharacters"
                            visible={toolTipVisible}
                            contentRender={ renderContent}
                        ></Tooltip>
                    </b>
                </Form.Label>
                <Col xs={12} md={2} style={{ marginBottom: "10px" }} id='colInvoiceNo'>
                    <Form.Control type="text"
                        name="InvoiceNo"
                        value={InvoiceNo}
                        onChange={onChangeInvoiceNo}
                        onKeyDown={onKeyDownInvoiceNo}
                        maxLength="50"
                    />                     
                </Col>                
                <Col xs={6} md={2}  >
                    <Button
                        width={200}
                        text="Search"
                        type="default"
                        onClick={useReCaptcha ? onSubmitWithReCAPTCHA : getData}
                    />
                    {useReCaptcha &&
                        <ReCAPTCHA
                            ref={reCaptchaRef}
                            size="invisible"
                            sitekey={reCaptchaSiteKey}
                            onChange={onChangeReCaptcha}
                        />
                    }
                </Col>
                <Col xs={6} md={2}  >
                    <Button
                        width={200}
                        text="Reset"
                        type="default"
                        onClick={reset}
                    />
                </Col>
                <Col xs={6} md={1}  >
                    <Button
                        width={200}
                        text="FAQ"
                        type="default"
                        onClick={showPopup}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" > 
                <Col xs={6} md={{ span: 2, offset: 4 }}>
                    <ToastContainer style={{maxWidth: '300px', paddingLeft: '20px'}}>
                        <Toast show={showInvalidCharacterMSG} onClose={toggleShowInvalidCharacterMSG} bg="warning">
                            <Toast.Header>
                                <strong className="me-auto">Invalid Character Detected</strong>
                                <small></small>
                            </Toast.Header>
                            <Toast.Body>A character was detected that is not allowed in the Invoice No. field.<br /><br />The following special characters will automatically be omitted from the Invoice Number : @  #  %  &  *  :  ;  ’  ”  /  ?.</Toast.Body>
                        </Toast>
                    </ToastContainer>
                </Col>
            </Form.Group>  
            <div className={"inline-errormsg"}>{errorMsg}</div>
            <div className='padding'>
                The Accounts Payable team is currently entering PO-invoices received on: <b>{workingReceivedDate}</b>&nbsp;
            <i className="dx-icon-help dx-icon-custom-style"
                id="spWSMessage"
                onMouseEnter={toggleWSDefault}
                onMouseLeave={toggleWSDefault}
                ></i>
               
            <Tooltip
                target="#spWSMessage"
                visible={toolWSTipVisible}
                contentRender={renderWSContent}
                ></Tooltip>
            </div>
            <DataGrid
                id="dgInvoice"
                dataSource={invoices}
                keyExpr="Id"
                rowAlternationEnabled={true}
                hoverStateEnabled={true}
                showBorders={true}
                columnAutoWidth={true}
            >
                <HeaderFilter visible={true} />
                <Column dataField="POnumber" caption="PO Number" />
                <Column dataField="InvoiceNo" caption="Invoice Number" />
                <Column dataField="SAPDocNo" caption="SAP Doc Number" />
                <Column dataField="Status" calculateCellValue={calculateStatusDisplayValue} />
                <Column dataField="OnHoldReason" caption="On-Hold Reason" calculateCellValue={calculateOnHoldReasonDisplayValue} minwidth={250} allowHiding={false} />
                <Column dataField="Modified" dataType="date" />
            </DataGrid>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{ of: '#dgInvoice' }}
                visible={loadPanelVisible}
                showIndicator={true}
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            ></LoadPanel>
            {sapMSGVisible && <div className='sap-message'>
                If your invoice has an SAP Doc No, review the document in SAP for workflow status and/or payment information. Instructions on how to check workflow on a document can be found <a href="https://atccl.sbcounty.gov/sap/knowledge-base/how-do-i-check-workflow-on-a-document/" target="_blank" rel="noopener noreferrer">here</a>. 
                Instructions on how to find ACH/Warrant info can be found <a href="https://atccl.sbcounty.gov/sap/knowledge-base/how-do-i-find-my-ach-warrant-info-using-my-document-number/" target="_blank" rel="noopener noreferrer">here</a>.
            </div>}
            <Popup
                visible={popupVisible}
                onHiding={hidePopup}
                showCloseButton={true}
                showTitle={true}
                title="Frequently Asked Questions"
                width={800}
                height={600}
            >
                <ScrollView width='100%' height='100%'>
                    <FAQ />
                </ScrollView>
            </Popup>           
        </div>
    );
}

export default InvoiceStatus;